import React, { useState, useEffect, useContext } from 'react'
import HospDocNavbar from "../../HospDocNavbar/HospDocNavbar";
import BdoNavbar from "../../BDO/BdoNavbar";
// import {useHistory} from "react-router-dom";
import axios from "axios";
import { APIURL } from "../../../Global";
import { Button, Modal, Card, ListGroup, ListGroupItem } from 'react-bootstrap';
import '../../css/bootstrap.min.css';
import '../../css/iofrm-style.css';
import '../../css/iofrm-theme4.css';
import "./popup.css";
import "./country_region_selector.css";
import "./dob.css";
import './profile.css';
import AddInfo from "./AddInfo";
import EditProfile from './EditProfile';
import { ProfileContext } from '../../contexts/ProfileContext'
import defaultprofile from "../../../assets/images/icons/profile.png";
import { RiUserLine } from "react-icons/ri";

// import VerifyOtp from '../VerifyOtp/VerifyOtp';


function Profile() {


  //const [img, setImg] =useState();
  const [state, setState] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [gender, setGender] = useState('');
  const [dob, setDob] = useState('');
  const [number, setNumber] = useState('');
  const [editShow, setEditShow] = useState(false);
  const [country, setCountry] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [img, setImg] = useState('')
  const [data, setData] = useState("");
  const [render, setRender] = useContext(ProfileContext)
  const [verifyShow, setVerifyShow] = useState(false)
  const [verified, setVerified] = useState()
  const [user, setUser] = useState('')
  const [otpShow, setOtpShow] = useState(false)
  const [dept, setDept] = useState("")
  const [otpFail, setOtpFail] = useState(false)
  const [disable, setDisable] = useState(false)
  const [addInfo, setAddinfo] = useState(false)
  const [login_datas, setLogin_datas] = useState(
    JSON.parse(sessionStorage.getItem("logindatas"))

  );

  const handleEditPopUp = (e) => {
    e.preventDefault();
    setEditShow(true)
  }


  useEffect(() => {

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')


    axios.get(`${APIURL}/api/v1/account/profile`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {


          setImg(res.data.data.photo)
          setName(res.data.data.full_name)
          setAddress(res.data.data.address)
          setGender(res.data.data.gender)
          setDob(res.data.data.dob)
          setEmail(res.data.data.email)
          setNumber(res.data.data.mobile_number)
          setState(res.data.data.state)
          setCountry(res.data.data.country_name)
          setVerified(res.data.data.email_verified)
          setUser(res.data.data.username)
          setDept(res.data.data.department)
        }


      })
      .catch(err => {

      })


  }, [refresh]);

  useEffect(() => {
    ;

    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')


    axios.get(`${APIURL}/api/v1/doctor/medical-profile-view/?doctor_id=${login_datas.user_id}`, {
      headers: {

        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.status == 200) {
          if (res.data && res.data.length > 0) {
            setData(res.data[0])
            // Setmedcomp(res.data[0].)
          }
        }


      })
      .catch(err => {

      })


  }, [refresh]);



  const verifyMail = () => {
    const tokenString = sessionStorage.getItem('usertoken');

    let str = tokenString.replace(/["]+/g, '')

    axios.get(`${APIURL}/api/v1/account/email-verification/`, {
      headers: {
        "Authorization": 'Token ' + str
      }
    })
      .then(res => {

        if (res.data.status === "success") {

          setVerifyShow(true)
        }
      })
  }





  const handleAdditionalInfo = () => {
    setAddinfo(true);
  }
  // const OtpPopUp = ({onHide}) => {
  //   return(
  //   <Modal
  //       show
  //       onHide
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       centered
  //        backdrop="static"
  //         keyboard={false}
  //     >
  //       <Modal.Header>
  //         <Modal.Title id="contained-modal-title-vcenter">

  //         </Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <VerifyOtp username={user} mail={email} handle={modalHandle} />

  //       </Modal.Body>
  //       <Modal.Footer>

  //          <Button variant="secondary" onClick={onHide}>Close</Button>          

  //       </Modal.Footer>
  //     </Modal>
  //   )
  // }

  const OtpFailPopUp = (props) => {
    return (
      <Modal
      {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 className='text-danger'>Error in Data Submission.Please Try Again.</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }


  const VerifyPopUp = (props) => {
    return (
      <Modal
      {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">

          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4 id="confirm-reject">Verification Link sent to Email</h4>

        </Modal.Body>
        <Modal.Footer>

          <Button variant="secondary" onClick={props.onHide}>Ok</Button>

        </Modal.Footer>
      </Modal>
    )
  }

  const handleHide = () =>
  { 
    setAddinfo(false);
    setRefresh(!refresh)

  }
  const AddInfoPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-dialog1"
        size="lg"
      >
        <Modal.Body className="modal-body1">

          <AddInfo show={addInfo} hide={handleHide} />
        </Modal.Body>

      </Modal>
    )
  }

  const EditPopUp = (props) => {
    return (
      <Modal
        {...props}
        aria-labelledby="example-custom-modal-styling-title"
        backdrop="static"
        keyboard={false}
        // dialogClassName="modal-dialog1"
        size=""
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">


          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body1">

          <EditProfile show={editShow} image={img} datas={data} medId={login_datas.user_id} />

        </Modal.Body>
      </Modal>
    )
  }
  function format(names) {
    if(names&&names!==""){
    const words =names.split(" ");

    const capitalizedWords = words.map(word => {
        const firstLetter = word.charAt(0).toUpperCase();
        const restOfWord = word.slice(1).toLowerCase();
        return firstLetter + restOfWord;
    });
    return capitalizedWords.join(" ");
}}
  return (
    <>
      {/* {login_datas.user_type === "hospital_doctor" ?  <h4>Doctor Profile</h4> :  <h4> Profile</h4> } */}



      <div className='' style={{ marginLeft: '2%', display: 'flex', width: 'auto', marginTop: "0%" }}>

        <div className="chip col-sm-2 " style={{ marginTop: '5%', padding:"1%", overflow:'hidden' }}>

          {img ? (<div className='avatar' style={{justifyContent:'center', alignItems:'center', margin:'auto',overflow:'hidden'}}> <img src={img} alt="Profile Photo"/></div> 
          ) : (
          <div className='avatar' style={{justifyContent:'center', alignItems:'center', margin:'auto'}}><RiUserLine style={{fontSize:'80px'}}/></div> 
          )}
          <div className="container">
            <h6  style={{color:"hwb(208deg 25% 0%)"}}>{format(name)}</h6>
             {login_datas.user_type === "hospital_doctor" ? <h6 ><b> Doctor</b></h6>
             :login_datas.user_type === "hospital_allied_prof"?<h6 ><b>Allied Professional</b></h6>
             :login_datas.user_type === "hospital_bdo"?<h6 ><b>Supervisor</b></h6>
             :login_datas.user_type === "hospital_pharmacist"?<h6><b>Pharmacist</b></h6>
             :login_datas.user_type === "hospital_labuser"?<h6><b>Lab User</b></h6>
             :login_datas.user_type ==="hospital_nurse" ?<h6 ><b>Nurse</b></h6>
             :login_datas.user_type ==="hospital_manager" ?<h6 ><b>Manager</b></h6>
             :login_datas.user_type ==="hospital_pro" ?<h6 ><b>PRO</b></h6>:""} 
          </div>
        </div>
        <div style={{display:"flex"}} className='col-lg-10'>

          <div >
            <Card className="mb-2" bg={'light'} border='light' style={{ width: '40rem', height: 'auto' }}>
              <div >


                {login_datas.user_type === "hospital_doctor" && data == "" ? <Button style={{ margin: '1rem' }} className='btn btn-primary btn-col' onClick={handleAdditionalInfo}> +Add Professional Info</Button> : null}
                <Button style={{ margin: '1rem' }} onClick={handleEditPopUp} className='btn btn-primary btn-col'>Edit Profile</Button>
              </div>
              <hr />



              <Card.Body>

                <h4 style={{ color: "green" }}><b>Basic Info</b></h4>
                <ListGroup style={{ paddingRight: "4%" }} className="list-group-flush">

                  <ListGroupItem><b>Email </b><span style={{ float: "right" }}> {email} </span></ListGroupItem>
                  <ListGroupItem><b>Gender </b> <span style={{ float: "right", textTransform:"capitalize" }}>{gender}</span></ListGroupItem>
                  <ListGroupItem><b>Hospital Name </b> <span style={{ float: "right", textTransform:"capitalize" }}>{login_datas.hospital_name}</span></ListGroupItem>
                  {login_datas.user_type === "hospital_doctor" ? <ListGroupItem><b>Department </b> <span style={{ float: "right", textTransform:"capitalize" }}>{dept}</span></ListGroupItem> :
                    login_datas.user_type === "hospital_allied_prof" ? <ListGroupItem><b>Department </b> <span style={{ float: "right", textTransform:"capitalize" }}>{dept}</span></ListGroupItem> :
                      login_datas.user_type === "hospital_nurse" ? <ListGroupItem><b>Department </b> <span style={{ float: "right", textTransform:"capitalize" }}>{dept}</span></ListGroupItem> : ""}
                  <ListGroupItem><b>Date of Birth </b><span style={{ float: "right" }}> {dob}</span></ListGroupItem>
                  <ListGroupItem><b>Mobile Number </b><span style={{ float: "right" }}> {number}</span></ListGroupItem>
                  <ListGroupItem><b>Address</b><span style={{ float: "right", textTransform:"capitalize" }}>{address}</span></ListGroupItem>
                  <ListGroupItem><b>Country </b> <span style={{ float: "right" }}>{country}</span></ListGroupItem>
                  <ListGroupItem><b>State  </b><span style={{ float: "right" }}>{state}</span></ListGroupItem>


                </ListGroup>

                {/* <Button disabled={disable} onClick={handleReset} className='btn btn-primary btn-col'>Reset Password</Button> */}
              </Card.Body>
            </Card>

          </div>
          {login_datas.user_type === "hospital_doctor" ?
            <Card className="mb-2" bg={'light'} border='light' style={{ width: '40rem', height: 'auto', marginTop: '9%',marginLeft:"2%" }}>
              <hr />

              <Card.Body>

                <h4 style={{ color: "red" }}><b>Professional Info</b></h4>
                <ListGroup className="list-group-flush">
                  <ListGroupItem><b>Medical Competence  </b><span style={{ float: "right" , textTransform:"capitalize"}}>{data.medical_competencies}</span></ListGroupItem>
                  <ListGroupItem><b>Super Speciality </b> <span style={{ float: "right", textTransform:"capitalize" }}>{data.specialization}</span></ListGroupItem>

                  <ListGroupItem><b>Year of Experience  </b><span style={{ float: "right" }}>{data.years_of_experience}</span></ListGroupItem>
                  <ListGroupItem><b>Reg.Year </b> <span style={{ float: "right" }}>{data.registeration_year}</span></ListGroupItem>
                  <ListGroupItem><b>Reg.Council </b><span style={{ float: "right" , textTransform:"capitalize"}}> {data.registration_council}</span></ListGroupItem>
                  <ListGroupItem><b>Official Practice Licence </b><span style={{ float: "right" }}> {data.official_practice_licenses}</span></ListGroupItem>
                  <ListGroupItem><b>Educational Credentials </b><span style={{ float: "right" }}> {data.educational_credentials}</span></ListGroupItem>
                </ListGroup>

              </Card.Body>

            </Card> : ""}

        </div>

        {
          editShow ?
            <EditPopUp
              show={editShow}
              onHide={() => {
                setEditShow(false)
                setRefresh(!refresh)
                setRender(!render)
              }} />
            : null
        }

        {
          verifyShow ?
            <VerifyPopUp
              show={verifyShow}
              onHide={() => {
                setVerifyShow(false)
              }} />
            : null
        }

        {/* {
            otpShow ? 
        <OtpPopUp
            show={otpShow}
            onHide= { ()=> {setOtpShow(false)
                           }} />
            : null
        }    */}

        {
          otpFail ?
            <OtpFailPopUp
              show={otpFail}
              onHide={() => {
                setOtpFail(false)
              }} />
            : null
        }
        {
          addInfo ?
            <AddInfoPopUp
              show={addInfo}
              onHide={() => {
                setAddinfo(false)
                setRefresh(!refresh)
              }} />
            : null
        }

      </div>
    </>


  )
}

export default Profile
