import React,{useState, useEffect} from "react";
import "./createpharmacyuser.css";
import axios from 'axios';

import { Link, useParams, useLocation, useNavigate } from "react-router-dom";

import {Modal} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import {APIURL} from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";

function CreatePharmacyUser(props) {
     const [hospData,] =useState(JSON.parse(sessionStorage.getItem('logindatas'))? JSON.parse(sessionStorage.getItem('logindatas')) : null);
    const [modalShow, setModalShow] = useState(false);
   const [username, setUsername] = useState("");
   const [password, setPassword] = useState("");
   const [confirmPassword, setConfirmPassword] = useState(null);
   const [submitMsg, setSubmitMsg]= useState("");
   const [passwordType, setPasswordType] = useState("password");
   const [passwordType1, setPasswordType1] = useState("password");
    const [error,setError]=useState({
    username:"",
    password: '',
    confirm_password:"",
   
   });
   const {id} =useParams();
   const hospital_username =  hospData.hospital_username;
   const { state } = useLocation()
   const name = state.name;
  const alert = useAlert();
  const navigate = useNavigate();
   const handleUserNameChange =(e)=>{
   
   	setUsername(e.target.value);
   }

    const handlePasswordChange =(e)=>{

   	setPassword(e.target.value);
   }
   const togglePasswordVisiblity = (e) =>
{

  if(passwordType==="password")
  {
   setPasswordType("text")
   return;
  }
  setPasswordType("password")
}
const togglePasswordVisiblity1 = (e) =>
{

  if(passwordType1==="password")
  {
   setPasswordType1("text")
   return;
  }
  setPasswordType1("password")
}
    const handleConfirmPassword =(e)=>{

    setConfirmPassword(e.target.value);
   }
   const validate=async ()=>{
   
    let errors = {};
    let isValid = true;
  setError({});

if (!username || username==="" || username===null) {
      isValid = false;
      errors["username"] = "Enter username prefix";
    }


    if (username !== "undefined") {
      var pattern1 = new RegExp(/^[a-zA-Z0-9]([._-](?![._-])|[a-zA-Z0-9]){0,18}[a-zA-Z0-9]$/);
      if (!pattern1.test(username) || username.length < 6) {
        isValid = false;
        errors["username"] = "Special Characters and space are not allowed in username and Characters limit is 6-20";
      }
    }

if (typeof password !== "undefined") {
        

    let pattern = new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!#^%*?&])([a-zA-Z0-9@$#^!%*?&]{8,})$/);
    if (!pattern.test(password)) {
      isValid = false;
      errors["password"] = "Password must contain at least 1 uppercase, 1 lower case, 1 number, 1 special character(@$!#^%*?&) and don't use space";
    }
  }
    if (!password || password==="" || password===null) {
      isValid = false;
      errors["password"] = "Enter password";
    }

    if (!confirmPassword || confirmPassword==="" || confirmPassword=== null || typeof confirmPassword === "undefined") {
      isValid = false;
      errors["confirm_password"] = "Enter confirm password";
    }

    if (password && typeof password !== "undefined") {
      if(password.length >0 && password.length < 8){
          isValid = false;
          errors["password"] = "Password must contain atleast 8 characters";
      }
    }

    if (typeof password !== "undefined" && password!=="" && password!==null && typeof confirmPassword !== "undefined" && confirmPassword!=="" && confirmPassword!== null) {
     if (password !== confirmPassword) {
        isValid = false;
        errors["confirm_password"] = "Passwords don't match";
      }
    }
   
      


    
       await setError(currentstate=>({
            ...currentstate,
            ...errors
          }));
      

      return isValid;
  }



   const handleSubmit=async (e)=>{
    e.preventDefault();
    if(await validate()){
    callSubmit();

  }
}
   const callSubmit=()=>{
   	

   	const tokenString = sessionStorage.getItem("usertoken");

    let v2 = tokenString.replace(
      /["]+/g,
      ""
    ); 

    
  const data = {
  	username: username+"@"+hospital_username,
  	password : password
  }

    axios.post(`${APIURL}/api/v1/service-provider/pharmacy-user/${id}/`, data, {
        headers: { 'Content-Type': 'application/json',
                     Authorization:  "Token "+v2 },
      })
      .then((res) => {
        if (res.data.status === "success") {
          alert.success('User credentials created successfully');
          navigate('/pharmacyusers-list')              
         
        } else {
        
           setSubmitMsg("Username already exist!!!");
        }
      })
      .catch((err) => {
       
         setSubmitMsg("error");
      });
  }

   
 return( 
 <>
 

 <div className="liner">

  </div>
    <div className="container">

    <h4 className="title-of-page">Pharmacy User Credentials </h4>
       <form className="create-pharm-user-form col-lg-6" onSubmit={handleSubmit}>
       
         <h5 className="pharmuser-details title-of-tasks"> Name : {name}</h5>
          <h5 className="pharmuser-details title-of-tasks"> Pharmacy User ID : {id} </h5> <br/>
       <div className="input-group mb-3">
          <input className="form-control" type="text" maxLength='30' placeholder="*Username" value={username} onChange={handleUserNameChange} />
          <div className="input-group-append">
    <span className="input-group-text postfix-username" id="basic-addon2">@{hospital_username}</span>
   
                                         
  </div>
   {error.username ?<div className="error-validation-msg"> {error.username}</div>: null} 
         </div>

         <div className="form-group ">
           <div className="form-control" style={{backgroundColor:"rgb(232, 240, 254)"}}>
            
           <input className="box1" type={passwordType} maxLength='30' placeholder="*Password" value={password} onChange={handlePasswordChange}  />
           
           <span style={{float:"right"}}>
        
                     { passwordType==="password"? <i onClick={togglePasswordVisiblity} className="fa fa-eye-slash"></i> :<i onClick={togglePasswordVisiblity} className="fa fa-eye"></i> }
                    </span>
         
            </div>
            {error.password ?<div className="error-validation-msg"> {error.password}</div>: null} 
         </div>

          <div className="form-group ">
            <div className="form-control "style={{backgroundColor:"rgb(232, 240, 254)"}}>
           <input className="box1" type={passwordType1} maxLength='30' placeholder="*Confirm Password" value={confirmPassword} onChange={handleConfirmPassword} />
           <span style={{float:"right"}}>
        
        { passwordType1==="password"? <i onClick={togglePasswordVisiblity1} className="fa fa-eye-slash"></i> :<i onClick={togglePasswordVisiblity1} className="fa fa-eye"></i> }
       </span>
       
        </div>    
        {error.confirm_password ?<div className="error-validation-msg"> {error.confirm_password}</div>: null}                         
         </div>
         {submitMsg!== "" ?
       <h5 style={{ color: "red" }}>
            
            {submitMsg}
          </h5>
      :
      null}
  <div className="form-button ">
          <button type="submit" className="btn btn-primary btn-col" >Generate User Credentials </button>
  </div>
    </form>

    </div>
 </>
);
}
export default CreatePharmacyUser;