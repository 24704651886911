import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useNavigate } from "react-router-dom";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import axios from "axios";
//import Navbar from "../common/Navbar";
import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";

import DatePicker from "react-date-picker";
import { APIURL } from "../../Global";
import HospitalNavbar from "../HospitalNavbar/HospitalNavbar";
import BackButton from "../common/BackButton";
import { useAlert } from "react-alert";

function AddPRO() {
  const [proDatas, setproDatas] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    code: "",
    email: "",
    country: "India",
    state: "",
    location: "",
    specializations: "",
    qualification: [],
    department: "",
    dob: "",
    gender: "",
  });

  const [error, setError] = useState({
    email: "",
    phone: "",
  });

  //const [dobValue, setDobValue] = useState();
  const [dobValue, setDobValue] = useState();
  const alert = useAlert();
  const [errorMsg, setErrorMsg] = useState("");

  const [, setFormattedDob] = useState("");

  const [submitMsg, setSubmitMsg] = useState("");
  const [validateRawPhoneMsg, setValidateRawPhoneMsg] = useState("");
  const [rawPhone, setRawPhone] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);

  const [errorShow, setErrorShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const navigate = useNavigate();
  const inputRefs = useRef([])
  const submitButtonRef = useRef (null)
  const handleKeyDown = (e, index) => {
    console.log("function")
    if (e.key === "Enter") {  
      e.preventDefault()
    console.log("function enter")

      if(index === 7 ) {
        submitButtonRef.current.click();
      }else{
      e.preventDefault();    
      console.log("Index:", index);
      console.log("Length:", inputRefs.current.length);
      const nextIndex = index + 1;
      console.log("Next index:", nextIndex);
      console.log("Next input field:", inputRefs.current[nextIndex]);
      if (nextIndex < inputRefs.current.length && inputRefs.current[nextIndex]) {
        // move focus to the next input if it exists
        inputRefs.current[nextIndex].focus();
      }
    }
    }
  };
  const handleKey = (e) => {
    if (e.key === "Enter") {
      e.preventDefault()
    console.log("useeffect enter")

      const activeElement = document.activeElement;
      if (
        !inputRefs.current.includes(activeElement) &&
        activeElement !== submitButtonRef.current
      ) {
        submitButtonRef.current.click();
      }
    }else if (e.altKey && e.key === 's') {
    console.log("useeffect alt and s")

      submitButtonRef.current.click(); 
    }else if (e.altKey && e.key === 'c') {
      navigate("../");
    }
  };

  useEffect(() => {
    console.log("useeffect")
    document.addEventListener("keydown", handleKey);
  
    return () => {
      document.removeEventListener("keydown", handleKey);
    };
  }, []);
  //const history = useHistory();



//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   useEffect(() => {
//     window.onkeydown = (e) => {
//       keys[e.key] = true;

//       if (e.altKey && keys["d"]) {
//         history.push("/doctors-list");
//       }
//       if (e.altKey && keys["a"]) {
//         history.push("/hospital/add");
//       }
//       if (e.altKey && keys["b"]) {
//         history.push("/pro-list");
//       }
//       if (e.altKey && keys["n"]) {
//         history.push("/emergency-number");
//       }
//       if (e.altKey && keys["s"]) {
//         history.push("/service-request-list");
//       }
//       if (e.altKey && keys["l"]) {
//         history.push("/license-details");
//       }
//       if (e.altKey && keys["p"]) {
//         history.push("/license-payment");
//       }
//       if (e.altKey && keys["c"]) {
//         history.push("/hosp-calllog");
//       }
//       if (e.altKey && keys["h"]) {
//         history.push("/payment-history");
//       }
//     };
//     window.onkeyup = (ev) => {
//       keys[ev.key] = false;
//     };

//     return () => {
//       window.onkeydown = null;
//       window.onkeyup = null;
//     };
//   }, []);

  const handleDOB = (date) => {
    
    setDobValue(date);

    let dateUpdated = date;
    if (date !== null) {
      const offset = date.getTimezoneOffset();

      dateUpdated = new Date(date.getTime() - offset * 60 * 1000);

      let DateExtracted = dateUpdated.toISOString().split("T")[0];
      //let datefinal = moment(DateExtracted).format('YYYY-MM-DD')  
      /*let dateString= DateExtracted.toString(); */
      setFormattedDob(DateExtracted);

      setproDatas((currentstate) => ({
        ...currentstate,
        dob: DateExtracted,
      }));
    }
    setError((currentstate) => ({
      ...currentstate,
      ["dob"]: "",
    }));
  };

  const handleGenderRadioButton = (e) => {
    let genderOpted = e.target.value;
    setproDatas((currentstate) => ({
      ...currentstate,
      gender: genderOpted,
    }));
    setError((currentstate) => ({
      ...currentstate,
      ["gender"]: "",
    }));
  };

  const handleSubmit = () => {
    
    if (rawPhone !== null && rawPhone !== "" && rawPhone !== " ") {
      setValidateRawPhoneMsg("success");
      const tokenStr = sessionStorage.getItem("usertoken");

      if (tokenStr) {
        const v = tokenStr;
        let v2 = v.replace(/["]+/g, "");

        const dataToSend = {
          name: proDatas.firstname + " " + proDatas.lastname,
          location: proDatas.location,
          state: proDatas.state,
          country: proDatas.country,
          specializations: proDatas.specializations,
          email: proDatas.email,
          qualification: proDatas.qualification,
          phone: proDatas.phone,
          code: proDatas.code,
          department: proDatas.department,
          dob: proDatas.dob,
          gender: proDatas.gender,
        };

        axios
          .post(`${APIURL}/api/v1/service-provider/pro/`, dataToSend, {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + v2,
            },
          })
          .then((res) => {
            if (res.data.status === "success") {
              alert.success(
                res.data.message ? res.data.message : "Successfully added"
              );
              navigate('/pro-list')
              sessionStorage.setItem("proname", res.data.name);
            } else {
              alert.error(
                res.data.message ? res.data.message : "Error in submission"
              );

              alert.error("Error in submission")
            }
          })
          .catch((err) => {
            alert.error("Error in submission");
          });
      }
    } else {
      setValidateRawPhoneMsg("error");
    }
  };

  const handleCheckEmail = (e) => {
    
    setIsValidEmail(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        email: "",
      }));
      e.preventDefault();
      let key = e.target.name;

      const data = {
        [e.target.name]: e.target.value,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                [key]: `This ${key} is already registered!`,
              }));
              setIsValidEmail(false);
            } else {
              setIsValidEmail(true);
            }
          } else {
            setIsValidEmail(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleCheckExistingPhone = (e) => {
    setIsValidPhone(true);
    if (e.target.value !== "") {
      setError((currentstate) => ({
        ...currentstate,
        phone: "",
      }));

      const data = {
        code: dialCode,
        mobile_number: rawPhone,
      };

      axios
        .post(`${APIURL}/api/v1/account/check-user/`, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.data.status === "success") {
            if (res.data.is_existing_user === true) {
              setError((currentstate) => ({
                ...currentstate,
                phone: "This phone number is already registered!",
              }));
              setIsValidPhone(false);
            } else {
              setIsValidPhone(true);
            }
          } else {
            setIsValidPhone(true);
          }
        })
        .catch((err) => {});
    }
  };

  const handleContinue = async (e) => {
    
    e.preventDefault();

    if (validate() && isValidEmail && isValidPhone) {
      handleSubmit();
    }
  };

  const handleChange = (e) => {
    
    e.preventDefault();
    const val = e.target.value;
    const key = e.target.name;

    setproDatas((currentstate) => ({
      ...currentstate,
      [key]: val,
    }));
    setError((currentstate) => ({
      ...currentstate,
      [key]: "",
    }));
  };

  const handleQualification = (e) => {
    e.preventDefault();
    const val = e.target.value;

    const key = e.target.name;
    let arr = [];
    if (val && val.includes(",")) {
      arr = val.split(",");
    } else {
      arr.push(val);
    }
    setproDatas((currentstate) => ({
      ...currentstate,
      [key]: arr,
    }));
    setError((currentstate) => ({
      ...currentstate,
      "qualification": "",
    }));
  };

  const handleCountry = (val) => {
    setproDatas((currentstate) => ({
      ...currentstate,
      country: val,
    }));
  };

  const handleRegion = (val) => {
    setproDatas((currentstate) => ({
      ...currentstate,
      state: val,
    }));
    setError((currentstate) => ({
      ...currentstate,
      ["state"]: "",
    }));
  };

  const handlePhoneInput = (value, data, event, formattedValue) => {
    setRawPhone(value.slice(data.dialCode.length));
    setDialCode(value.slice(0, data.dialCode.length));
    setproDatas((currentstate) => ({
      ...currentstate,
      phone: value.slice(data.dialCode.length),
      code: value.slice(0, data.dialCode.length),
    
    }));
    setError((currentstate) => ({
      ...currentstate,
      "phone": "",
    }));
  };

  const validate = () => {
    let input = proDatas;
    let errors = {};
    let isValid = true;
    setError({});

    if (!input["firstname"] || input["firstname"] === "") {
      isValid = false;
      errors["firstname"] = "Enter first name";
    }

    if (!input["lastname"] || input["lastname"] === "") {
      isValid = false;
      errors["lastname"] = "Enter last name";
    }

    if (!input["specializations"] || input["specializations"] === "") {
      isValid = false;
      errors["specializations"] = "Enter specializations";
    }

    if (!input["location"] || input["location"] === "") {
      isValid = false;
      errors["location"] = "Enter location";
    }

    if (!input["country"] || input["country"] === "") {
      isValid = false;
      errors["country"] = "Select a country";
    }

    if (!input["state"] || input["state"] === "") {
      isValid = false;
      errors["state"] = "Select a state";
    }

    if (
      !input["qualification"] ||
      input["qualification"].length === 0 ||
      input["qualification"][0] === ""
    ) {
      isValid = false;
      errors["qualification"] = "Enter qualification";
    }

    if (!dialCode || dialCode === "" || !rawPhone || rawPhone === "") {
      isValid = false;
      errors["phone"] = "Enter phone number";
    }

    if (
      typeof dobValue == "undefined" ||
      dobValue === "" ||
      dobValue === null
    ) {
      isValid = false;
      errors["dob"] = "Enter your date of birth";
    }
    if (
      typeof proDatas.gender == "undefined" ||
      proDatas.gender === "" ||
      proDatas.gender === null
    ) {
      isValid = false;
      errors["gender"] = "Select gender";
    }

    if (typeof input["email"] !== "undefined") {
      var pattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!pattern.test(input["email"])) {
        isValid = false;
        errors["email"] = "Enter a valid email address.";
      }
    }
    // if (typeof input["department"] !== "undefined") {
    //   var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
    //   if(pattern2.test(input["department"])) {
    //     isValid = false;
    //     errors["specialization"] = "Special Characters are not allowed";
    //   }
    // }

    if (typeof input["qualification"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/?~]/);
      if (pattern2.test(input["qualification"])) {
        isValid = false;
        errors["qualification"] = "Check the characters used";
      }
    }
    if (typeof input["specializations"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/.?~]/);
      if (pattern2.test(input["specializations"])) {
        isValid = false;
        errors["specializations"] = "Check the characters used";
      }
    }
    if (typeof input["department"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\=\[\]{};':"\\|<>\/.?~]/);
      if (pattern2.test(input["department"])) {
        isValid = false;
        errors["department"] = "Check the characters used";
      }
    }
    if (typeof input["firstname"] !== "undefined") {
      var pattern1 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern1.test(input["firstname"])) {
        isValid = false;
        errors["firstname"] = "Special Characters are not allowed";
      }
    }

    if (input["firstname"] && typeof input["firstname"] !== "undefined") {
      if (input["firstname"].length > 0 && input["firstname"].length < 3) {
        isValid = false;
        errors["firstname"] = "Must contain atleast 3 characters";
      }
    }

    if (typeof input["lastname"] !== "undefined") {
      var pattern2 = new RegExp(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/.?~]/);
      if (pattern2.test(input["lastname"])) {
        isValid = false;
        errors["lastname"] = "Special Characters are not allowed";
      }
    }

    if (!input["email"]) {
      isValid = false;

      errors["email"] = "Enter email Address.";
    }

    if (isValidPhone === false) {
      isValid = false;
      errors["phone"] = "Phone number already registered!";
    }

    if (rawPhone.length > 0 && rawPhone.length < 10) {
      isValid = false;
      errors["phone"] = "Check phone number";
    }
    if (isValidEmail === false) {
      isValid = false;
      errors["email"] = "Email already registered!";
    }

    setError((currentstate) => ({
      ...currentstate,
      ...errors,
    }));

    return isValid;
  };


  return (
    <div className="add-dr-page">
 

      <div className="add-dr-form-holder">

        <div className="quick-links">
          <Link to="/pro-list">
            <button type="button" className="btn btn-primary btn-col dr-btn">
              {" "}
              Goto PRO List{" "}
            </button>
          </Link>
        </div>
        <form className="add-dr-form" onSubmit={handleContinue}>
          <h4 className="title-of-page"> Add PRO Details</h4>
          <div className="add-dr-div">
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* First Name: </label>
              <input
                ref={el => inputRefs.current[0] = el}
                onKeyDown={(e) => handleKeyDown(e,0)}
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="firstname"
                onChange={handleChange}
              />
              {error.firstname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.firstname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Last Name: </label>

              <input
                ref={el => inputRefs.current[1] = el}
                onKeyDown={(e) => handleKeyDown(e,1)}
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="lastname"
                onChange={handleChange}
              />
              {error.lastname ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.lastname}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Email Address: </label>

              <input
              ref={el => inputRefs.current[2] = el}
              onKeyDown={(e) => handleKeyDown(e,2)}
                className="form-control "
                type="email"
                maxLength="50"
                placeholder=""
                name="email"
                onChange={handleChange}
                onBlur={handleCheckEmail}
              />
              {error.email ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.email}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Phone: </label>

              <PhoneInput
                inputProps={{
                  ref: el => inputRefs.current[3] = el,
                  tabIndex: 0,
                  onKeyDown: e => handleKeyDown(e, 3),
                }}
                country={"in"}
                onlyCountries={['in']}
                countryCodeEditable={false}
                onChange={handlePhoneInput}
                onBlur={handleCheckExistingPhone}
              />
              {error.phone ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.phone}
                </div>
              ) : null}

              {submitMsg !== null && submitMsg !== "" ? (
                <p style={{ color: "red" }}>{submitMsg}</p>
              ) : (
                ""
              )}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Qualifications: </label>

              <input
                ref={el => inputRefs.current[4] = el}
                onKeyDown={(e) => handleKeyDown(e,4)}
                className="form-control"
                type="text"
                maxLength="150"
                placeholder=""
                name="qualification"
                onChange={handleQualification}
              />
              {error.qualification ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.qualification}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 item-left-row">
              <label className="">* Specializations: </label>

              <input
                ref={el => inputRefs.current[5] = el}
                onKeyDown={(e) => handleKeyDown(e,5)}
                className="form-control"
                type="text"
                maxLength="150"
                placeholder=""
                name="specializations"
                onChange={handleChange}
              />
              {error.specializations ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.specializations}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> Department: </label>

              <input
                ref={el => inputRefs.current[6] = el}
                onKeyDown={(e) => handleKeyDown(e,6)}
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="department"
                onChange={handleChange}
              />
              {error.department ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.department}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 item-left-row">
              <label className=""> * Location/ City : </label>

              <input
                ref={el => inputRefs.current[7] = el}
                onKeyDown={(e) => handleKeyDown(e,7)}
                className="form-control"
                type="text"
                maxLength="50"
                placeholder=""
                name="location"
                onChange={handleChange}
              />
              {error.location ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {error.location}
                </div>
              ) : null}
            </div>

            <div className="form-group dob-check col-lg-6">
              {/* <div className="dob-container form-control"> */}
                <span>Date of Birth * : </span>
                <DatePicker
                onChange={date => { handleDOB(date) }}

                maxDate={new Date()}
                value={dobValue}

              />
              {/* </div> */}
              {error.dob ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.dob}
                </div>
              ) : null}
            </div>

            <div className="form-group gender-radio-check col-lg-6">
              <div className="form-control">
                <span>*Gender:</span>&nbsp;
                <input
                  type="radio"
                  id="male"
                  name="gender"
                  value="male"
                  checked={proDatas.gender === "male"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="male">Male</label>

                <input
                  type="radio"
                  id="female"
                  name="gender"
                  value="female"
                  checked={proDatas.gender === "female"}
                  onChange={handleGenderRadioButton}
                />

                <label htmlFor="female">Female</label>

                <input
                  type="radio"
                  id="other"
                  name="gender"
                  value="other"
                  checked={proDatas.gender === "other"}
                  onChange={handleGenderRadioButton}
                />
                <label htmlFor="other">Other</label>
              </div>
              {error.gender ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.gender}
                </div>
              ) : null}
            </div>

            <div className="form-group col-lg-6 country-selector-dr">
              <label className="">Country: </label>
                <input
                  className="form-control"
                  type="text"
                  value={proDatas.country}
                  maxLength="150"
                  disabled={true}
                />
              {/* <CountryDropdown
               disabled={true}
              
                value={proDatas.country}
                onChange={(val) => handleCountry(val)}
              /> */}
              {error.country ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.country}
                </div>
              ) : null}
            </div>
            <div className="form-group col-lg-6 region-selector-dr">
              <label className="">* State: </label>

              <RegionDropdown
              defaultOptionLabel={'Select State'}
                country={proDatas.country}
                value={proDatas.state}
                onChange={(val) => handleRegion(val)}
              />
              {error.state ? (
                <div
                  style={{ zIndex: "auto" }}
                  className="error-validation-msg"
                >
                  {" "}
                  {error.state}
                </div>
              ) : null}
             
             
            </div>
          </div>
          <div className="form-button">
          <button 
            ref={submitButtonRef}
            type="submit" 
            className="btn btn-primary btn-col">      
            
            {" "}
            Submit Data
          </button>{" "}
          </div>
          <br />
          <br />
          <h6 className="text-muted">All fields marked * are mandatory</h6>
        </form>

        {/************************Unused***************
    {(() => {
      switch(submitMsg){
      case "success":
      return   (
          <div>
            <h5 style={{ color: "green" }}> Data submitted sucessfully !!</h5>{" "}

            


            <br />
            <br />
           
          
            
          </div>
        );

        case "error": 
        return (
          <h5 style={{ color: "red" }}>
            
            Error in data submission.Please check again!
          </h5>
        );
        default: 
        return "";
           }
      })()}

    *************************/}

        {validateRawPhoneMsg === "error" ? (
          <h5 style={{ color: "red" }}>Phone number field is mandatory!</h5>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default AddPRO;
